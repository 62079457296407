import React from 'react'
import {Credits} from './footer.elements'

const Footer = () => {
   

  return (
    <>
        <Credits>
            <h1>ASD-DEVELOPER</h1>
            <h2>produced, remixed and mastered.</h2>
        </Credits>
    </>
  );
}

export default Footer;
