import React from 'react';
import { Container, IconRow, } from './stack.elements'

const MyStack = () => {
  return (
    <Container>
      <h2>My Stack:</h2>
      <IconRow>
        
        <div>
          <img src="stack/javascript icon.png" alt="JavaScript" />
          <span className={"caption"}>JavaScript</span>
        </div>
        <div>
          <img src="stack/vue icon.png" alt="Vue" />
          <span className={"caption"}>Vue</span>
        </div>
        <div>
          <img src="stack/react icon.png" alt="ReactJs" />
          <span className={"caption"}>ReactJS</span>
        </div>
        <div>
          <img src="stack/nodejs icon.png" alt="NodeJs" />
          <span className={"caption"}>NodeJS</span>
        </div>
        <div>
          <img src="stack/typescript icon.png" alt="TypeScript" />
          <span className={"caption"}>TypeScript</span>
        </div>
        <div>
          <img src="stack/html icon.png" alt="HTML" />
          <span className={"caption"}>HTML</span>
        </div>
        <div>
          <img src="stack/css icon.png" alt="CSS" />
          <span className={"caption"}>CSS</span>
        </div>
      </IconRow>
      <div>
        <h2>Others:</h2>
        <h3>Git, Redux, Vuex, MongoDB, Sass, Jest, mySQL, <br/> CICD, Docker, AWS, Azure, Python, Golang</h3>
      </div>
    </Container>
  );
}

export default MyStack;
